import * as React from 'react'
import { Link } from 'gatsby'
import { AuthLayout } from '../components/AuthLayout'
import Button from '../components/Button'
import CafeZincanLogo from '../../assets/svgs/CafeZincanLogo.svg'

const OnTheList = () => {
  return (
    <>
      <AuthLayout>
        <div className="flex flex-col mb-12">
          <Link to="/" aria-label="Home">
            <CafeZincanLogo />
          </Link>
          <div className="mt-20 text-xl">
            You're all set!  We will notify you when we are ready for your site.
          </div>
        </div>
        <Button color="primary" href="/">
            <span aria-hidden="true" className="mr-2">&larr;</span>Back to Home
        </Button>
        <div className="pb-96"></div>
        <div className="pb-32"></div>
      </AuthLayout>
    </>
  )
}

export default OnTheList;

export const Head = () => <title>Café Zincan - Waiting List</title>;

